import styled from "@emotion/styled/macro";
import _ from "lodash";
import { AssessmentScenarioData, BehaviorParameter, BehaviorParameterTemplate, InputData, Optional } from "../../../../../types/filters";
import { Flex, Select, Text, TextProps, TextInput, NumberInput, useMantineTheme } from "@mantine/core";
import { ReactNode, useContext, useEffect, useState } from "react";
import { AppInfoContext } from "../../../../../components/contexts/AppInfoContext";
import { prettifyPropertyName } from "../../../../../common/prettifyPropertyName";
import InputTooltip from "./inputs/InputTooltip";
import QueueInput from "./inputs/queue-input/QueueInput";
import VoronoisLayer from "../layers/VoronoisLayer";
import * as Cesium from "cesium";
import RadiusLayer from "../layers/RadiusLayer";
import PathLayer from "../layers/PathLayer";
import { BehaviorEditiorContext } from "../contexts/BehaviorEditiorContext";
import DeploymentZoneLayer from "../layers/DeploymentZoneLayer";
import {
	getBehaviorName,
	getMovingBehavior,
	getPlanningAction,
	getPreviousQueuePropertyFromPath,
} from "../functions/behaviorSettingsFunctions";

interface IBehaviorPanel {
	cesiumViewer?: Cesium.Viewer;
	entityId: number; // May be agent_id or echelon id
	inputData: InputData;
	systemId: number;
	scenarioSettings: Optional<AssessmentScenarioData, "id">;
	behaviorSettings: Record<number, BehaviorParameter[]>;
	isEditionModeOn: boolean;
	isCommander?: boolean;
	topLevelSettings?: BehaviorParameterTemplate[];
	isEchelonLevel?: boolean;
	preview?: boolean;
	setLayerControls?: (value: JSX.Element | null) => void; // To display controls outside behavior panel (on cesium editor view)
	setSetting: (value: Record<number, BehaviorParameter[]> | null, duplicate?: boolean, changeTopLevelName?: boolean) => void;
}

const BehaviorPanel: React.FC<IBehaviorPanel> = ({
	cesiumViewer,
	inputData,
	systemId,
	entityId,
	scenarioSettings,
	behaviorSettings,
	isEditionModeOn,
	topLevelSettings,
	isEchelonLevel,
	isCommander,
	preview,
	setLayerControls,
	setSetting,
}) => {
	const { isSuperuser } = useContext(AppInfoContext);
	const { allEntities, resetAgentIdEntities } = useContext(BehaviorEditiorContext);
	const theme = useMantineTheme();
	const schemeColors = theme.colors[theme.colorScheme === "dark" ? "dark" : "light"];
	const [movingBehavior, setMovingBehavior] = useState<string | null>(getBehaviorFromSettings("MOVING_BEHAVIOR"));
	const [planningAction, setPlanningAction] = useState<string | null>(getBehaviorFromSettings("PLANNING_ACTION"));
	const [currentMovingTemplate, setCurrentMovingTemplate] = useState<BehaviorParameterTemplate[] | null>(null);
	const [currentPlanningTemplate, setCurrentPlanningTemplate] = useState<BehaviorParameterTemplate[] | null>(null);
	const [systemIds] = useState<{ agent_id: number; system_id: number; is_commander: boolean }>({
		agent_id: entityId,
		system_id: systemId,
		is_commander: isCommander ? isCommander : false,
	});

	function getBehaviorFromSettings(behavior_type: "MOVING_BEHAVIOR" | "PLANNING_ACTION") {
		return (behaviorSettings[entityId]?.find((param) => param.property === behavior_type)?.settings?.[0].value as string) || null;
	}

	const updateParameter = (path: (string | number)[], newParameter: BehaviorParameter) => {
		const obj = _.set(behaviorSettings[entityId], path, newParameter);
		const newBehaviorSettings = { ...behaviorSettings, [entityId]: obj as BehaviorParameter[] };
		setSetting(newBehaviorSettings);
	};

	const getParameterInput = (
		parameterTemplate: BehaviorParameterTemplate,
		keyIndex: number,
		path: (string | number)[],
		disabledParent: boolean,
		showName: boolean = false,
		queueInputIndex?: number // If input belongs to queue, this is the index of the queue input
	) => {
		let elementOnWhichInputDepends: BehaviorParameter | undefined;
		if (parameterTemplate.dependsOn) {
			elementOnWhichInputDepends = _.get(behaviorSettings[entityId], path.slice(0, -1))?.find(
				(el: BehaviorParameter) => el.property === parameterTemplate.dependsOn
			); // -1 to go one level up in the path and see on which sibling it depends on
		}
		if (queueInputIndex && queueInputIndex > 1 && !elementOnWhichInputDepends) {
			// If input belongs to queue, the element on which input depends is always the higher
			// element in the queue (queueInputIndex - 1). Don't need to check for dependsOn property
			elementOnWhichInputDepends = getPreviousQueuePropertyFromPath(behaviorSettings[entityId], path); // Assuming that index no. 1 is always the main input (0 is for name)
		}

		const disabled =
			!isSuperuser || (elementOnWhichInputDepends !== undefined && elementOnWhichInputDepends.value === -1) || disabledParent;

		const defaultValue =
			_.get(behaviorSettings[entityId], path)?.value !== undefined
				? _.get(behaviorSettings[entityId], path)?.value
				: parameterTemplate.default;

		if (parameterTemplate.settings && parameterTemplate.type !== "actionQueue") {
			const nestedParameters: ReactNode[] = parameterTemplate.settings.map((param, ix) => {
				return getParameterInput(param, ix, [...path, "settings", ix], disabled);
			});

			return [
				<InputTooltip
					key={keyIndex + "tooltip-nested" + path.join("-")}
					disabled={!disabled || disabledParent}
					elementOnWhichInputDepends={elementOnWhichInputDepends}
				>
					<div>
						<InputLabel key={keyIndex + "label-nested" + path.join("-")} color={disabled ? schemeColors[4] : "inherit"}>
							{prettifyPropertyName(parameterTemplate.property)}
						</InputLabel>
						<Flex
							key={keyIndex + "input-nested" + path.join("-")}
							p={"1rem"}
							style={{
								border: `1px solid ${disabled ? schemeColors[4] : schemeColors[5]}`,
								flexDirection: "column",
								borderRadius: "0.25rem",
							}}
						>
							{nestedParameters}
						</Flex>
					</div>
				</InputTooltip>,
			];
		}

		const previewComponent = (
			<TextInput
				contentEditable={false}
				key={keyIndex + "string" + (defaultValue || "")}
				defaultValue={defaultValue || ""}
				disabled
				onBlur={(e) => {
					const newParameterData = {
						property: parameterTemplate.property,
						value: e.target.value,
					} as BehaviorParameter;
					updateParameter(path, newParameterData);
				}}
				w={"100%"}
			></TextInput>
		);

		const inputs: Record<string, JSX.Element> = {
			string: (
				<TextInput
					key={keyIndex + "string" + (defaultValue || "")}
					defaultValue={defaultValue || ""}
					disabled={disabled}
					onBlur={(e) => {
						const newParameterData = {
							property: parameterTemplate.property,
							value: e.target.value,
						} as BehaviorParameter;
						updateParameter(path, newParameterData);
					}}
					w={"100%"}
				></TextInput>
			),

			integer: (
				<StyledNumberInput
					key={keyIndex + "number" + (defaultValue || "")}
					w={"100%"}
					defaultValue={parseInt(defaultValue)}
					disabled={disabled}
					onBlur={(e) => {
						const newParameterData = {
							property: parameterTemplate.property,
							value: parseInt(e.target.value),
						} as BehaviorParameter;
						updateParameter(path, newParameterData);
					}}
				></StyledNumberInput>
			),

			double: (
				<StyledNumberInput
					key={keyIndex + "double" + (defaultValue || "")}
					w={"100%"}
					defaultValue={parseFloat(defaultValue)}
					step={0.5}
					precision={1}
					disabled={disabled}
					onBlur={(e) => {
						const newParameterData = {
							property: parameterTemplate.property,
							value: e.target.value.toString(),
						} as BehaviorParameter;
						updateParameter(path, newParameterData);
					}}
				></StyledNumberInput>
			),

			mapNode: preview ? (
				previewComponent
			) : cesiumViewer ? (
				<VoronoisLayer
					key={keyIndex + "voronois" + (defaultValue || "") + queueInputIndex}
					viewer={cesiumViewer}
					defaultValue={defaultValue}
					systemSettings={scenarioSettings.systems[systemId]}
					systemIds={systemIds}
					onChange={(value: any) => {
						const newParameterData = {
							property: parameterTemplate.property,
							value: value,
						} as BehaviorParameter;
						updateParameter(path, newParameterData);
					}}
					setLayerControls={setLayerControls}
					isEditionModeOn={isEditionModeOn}
					queueInputIndex={queueInputIndex}
					elementOnWhichInputDepends={elementOnWhichInputDepends}
				></VoronoisLayer>
			) : (
				<></>
			),

			mapRadius: preview ? (
				previewComponent
			) : cesiumViewer ? (
				<RadiusLayer
					key={keyIndex + "radius" + (defaultValue || "") + queueInputIndex}
					viewer={cesiumViewer}
					defaultValue={defaultValue}
					systemSettings={scenarioSettings.systems[systemId]}
					systemIds={systemIds}
					voronoisDataSource={inputData.geometriesDataSource}
					elementOnWhichInputDepends={elementOnWhichInputDepends}
					onChange={(value: any) => {
						const newParameterData = {
							property: parameterTemplate.property,
							value: value,
						} as BehaviorParameter;
						updateParameter(path, newParameterData);
					}}
					setLayerControls={setLayerControls}
					isEditionModeOn={isEditionModeOn}
					queueInputIndex={queueInputIndex}
				></RadiusLayer>
			) : (
				<></>
			),

			mapPath: preview ? (
				previewComponent
			) : cesiumViewer ? (
				<PathLayer
					key={keyIndex + "path" + (defaultValue || "") + queueInputIndex}
					viewer={cesiumViewer}
					defaultValue={defaultValue}
					systemSettings={scenarioSettings.systems[systemId]}
					systemIds={systemIds}
					onChange={(value: any) => {
						const newParameterData = {
							property: parameterTemplate.property,
							value: value,
						} as BehaviorParameter;
						updateParameter(path, newParameterData);
					}}
					setLayerControls={setLayerControls}
					isEditionModeOn={isEditionModeOn}
					queueInputIndex={queueInputIndex}
					elementOnWhichInputDepends={elementOnWhichInputDepends}
				></PathLayer>
			) : (
				<></>
			),

			deploymentZone: preview ? (
				previewComponent
			) : cesiumViewer ? (
				<DeploymentZoneLayer
					key={keyIndex + "deploymentZone" + (defaultValue || "") + queueInputIndex}
					viewer={cesiumViewer}
					defaultValue={defaultValue}
					onChange={(value) => {
						const newParameterData = {
							property: parameterTemplate.property,
							value: value !== null ? (typeof value === "number" ? value : parseInt(value)) : value,
						} as BehaviorParameter;
						updateParameter(path, newParameterData);
					}}
				></DeploymentZoneLayer>
			) : (
				<></>
			),

			actionQueue: (
				<QueueInput
					key={keyIndex + "queue" + (defaultValue || "")}
					parameterTemplate={parameterTemplate}
					behaviorSettings={behaviorSettings}
					agentId={entityId}
					previewMode={preview}
					setSetting={setSetting}
					handleBehaviorChange={handleBehaviorChange}
					getParameterInput={getParameterInput}
					getDefaultParameters={getDefaultParameters}
					onChange={(value: any) => {
						const newParameterData = {
							property: parameterTemplate.property,
							value: value,
						} as BehaviorParameter;
						updateParameter(path, newParameterData);
					}}
					{...{ inputData, scenarioSettings, disabled }}
				/>
			),
		};

		const isCondition = _.get(behaviorSettings[entityId], path.slice(0, -2))?.property === "CONDITION";

		const defaultInput = (
			<InputWrapper>
				<InputLabel key={keyIndex + "label"} color={disabled ? schemeColors[4] : "inherit"}>
					{prettifyPropertyName(parameterTemplate.property)}
				</InputLabel>
				<TextInput
					key={keyIndex + "input"}
					defaultValue={defaultValue}
					disabled={disabled}
					onChange={(e) => {
						const newParameterData = {
							property: parameterTemplate.property,
							value: e.target.value,
						} as BehaviorParameter;
						updateParameter(path, newParameterData);
					}}
					w={"100%"}
				></TextInput>
			</InputWrapper>
		);

		return (
			(parameterTemplate.property !== "NAME" || showName) && (
				<InputTooltip
					key={keyIndex + "tooltip"}
					disabled={!disabled || disabledParent}
					elementOnWhichInputDepends={elementOnWhichInputDepends}
				>
					{inputs[parameterTemplate.type as string] ? (
						<InputWrapper>
							<InputLabel key={keyIndex + "label"} color={disabled ? schemeColors[4] : "inherit"}>
								{prettifyPropertyName(
									isCondition && parameterTemplate.property === "NAME" ? "Condition" : parameterTemplate.property
								)}
							</InputLabel>
							{inputs[parameterTemplate.type as string]}
						</InputWrapper>
					) : (
						defaultInput
					)}
				</InputTooltip>
			)
		);
	};

	const handleBehaviorChange = (
		behaviorType: "MOVING_BEHAVIOR" | "PLANNING_ACTION",
		value: string | null,
		path: (string | number)[],
		queueInputIndex?: number
	) => {
		let newBehaviorSettings = _.cloneDeep(behaviorSettings);

		if (path.length === 0) {
			// Handle top-level behavior change
			let newParameters: BehaviorParameter[] = newBehaviorSettings[entityId] ? [...newBehaviorSettings[entityId]] : [];
			const correspondingBehaviorIx = newParameters.findIndex((parameter) => parameter.property === behaviorType);
			const newBehavior = value
				? ({
						property: behaviorType,
						settings: [{ property: "NAME", value: value }, ...getDefaultParameters(value)],
				  } as BehaviorParameter)
				: null;

			if (correspondingBehaviorIx !== -1) {
				value
					? (newParameters[correspondingBehaviorIx] = newBehavior as BehaviorParameter)
					: newParameters.splice(correspondingBehaviorIx, 1);
			} else {
				value ? newParameters.push(newBehavior as BehaviorParameter) : (newParameters = []);
			}

			newBehaviorSettings = { ...newBehaviorSettings, [entityId]: newParameters };
		} else {
			// Handle nested behavior change
			if (value) {
				// Update the nested behavior with new settings
				const newSettings = [{ property: "NAME", value: value }, ...getDefaultParameters(value)];
				_.set(newBehaviorSettings, [entityId, ...path, "settings"], newSettings);
			} else {
				// If value is null, remove the nested behavior
				_.unset(newBehaviorSettings, [entityId, ...path]);
			}
		}

		// Remove old entities
		if (cesiumViewer) {
			if (queueInputIndex) resetAgentIdEntities(entityId.toString() + "-" + queueInputIndex, true);
			else {
				const queueInputKeys = Object.keys(allEntities).filter(
					(key) => key === entityId.toString() || key.split("-")[0] === entityId.toString()
				);
				queueInputKeys.forEach((key) => {
					resetAgentIdEntities(key, true);
				});
			}
		}

		setSetting(newBehaviorSettings);
	};

	const getDefaultParameters = (behavior_name: string) => {
		const getDefaultsRecursively = (template: BehaviorParameterTemplate[]) => {
			const defaults: BehaviorParameter[] = [];

			template.forEach((parameterTemplate) => {
				if (parameterTemplate.settings) {
					const nestedDefaults = getDefaultsRecursively(parameterTemplate.settings);
					defaults.push({ property: parameterTemplate.property, settings: nestedDefaults });
				} else {
					defaults.push({
						property: parameterTemplate.property,
						value: parameterTemplate.default,
					} as BehaviorParameter);
				}
			});

			return defaults;
		};

		if (inputData.behaviors) {
			const template = inputData.behaviors.find((behavior) => behavior.name === behavior_name)?.template;
			return template ? getDefaultsRecursively(template) : [];
		}

		return [];
	};

	useEffect(() => {
		if (inputData.behaviors) {
			const behavior = inputData.behaviors.find((behavior) => behavior.name === movingBehavior);
			behavior ? setCurrentMovingTemplate(behavior.template) : setCurrentMovingTemplate(null);
		}
	}, [movingBehavior, inputData]);

	useEffect(() => {
		if (inputData.behaviors) {
			const behavior = inputData.behaviors.find((behavior) => behavior.name === planningAction);
			behavior ? setCurrentPlanningTemplate(behavior.template) : setCurrentPlanningTemplate(null);
		}
	}, [planningAction, inputData]);

	useEffect(() => {
		setMovingBehavior(getBehaviorFromSettings("MOVING_BEHAVIOR"));
		setPlanningAction(getBehaviorFromSettings("PLANNING_ACTION"));
	}, [scenarioSettings, entityId]);

	useEffect(() => {
		// Prepare default settings if don't exist for topLevelSettings
		if (topLevelSettings) {
			topLevelSettings.forEach((setting) => {
				if (
					behaviorSettings[entityId] === undefined ||
					(behaviorSettings[entityId] && behaviorSettings[entityId].find((el) => el.property === setting.property) === undefined)
				) {
					setSetting({
						...behaviorSettings,
						[entityId]: [
							...(behaviorSettings[entityId] ? behaviorSettings[entityId] : []),
							{ property: setting.property, value: setting.default },
						],
					});
				}
			});
		}
	}, [topLevelSettings, behaviorSettings, entityId, setSetting]);

	return (
		<BehaviorsContainer>
			{topLevelSettings && behaviorSettings[entityId] ? (
				<ParametersWrapper key="top-level-settings" mt={"1rem"}>
					{topLevelSettings.map((parameterTemplate, ix) => {
						const settingIndex = behaviorSettings[entityId].findIndex((el) => el.property === parameterTemplate.property);
						return (
							<InputWrapper key={"top-level-settings-input-" + ix} style={{ flex: 1 }}>
								{getParameterInput(parameterTemplate, ix, [...(settingIndex !== -1 ? [settingIndex] : [])], false)}
							</InputWrapper>
						);
					})}
				</ParametersWrapper>
			) : null}

			{!isEchelonLevel ? (
				<Flex style={{ flexDirection: "row" }}>
					<BehaviorSection style={{ marginRight: "0.5rem" }}>
						<InputWrapper>
							<InputLabel>Moving Behavior</InputLabel>
							{inputData.behaviors ? (
								<BehaviorSelect
									key={getBehaviorName(getMovingBehavior(behaviorSettings[entityId])) || null}
									defaultValue={getBehaviorName(getMovingBehavior(behaviorSettings[entityId])) || null}
									data={inputData.behaviors
										.filter((behavior) => behavior.type === "MOVING_BEHAVIOR")
										.map((behavior) => ({ label: behavior.name, value: behavior.name }))}
									onChange={(value) => {
										setMovingBehavior(value);
										handleBehaviorChange("MOVING_BEHAVIOR", value, []);
									}}
									disabled={!isSuperuser || preview}
									clearable
								></BehaviorSelect>
							) : null}
						</InputWrapper>

						<ParametersWrapper>
							{currentMovingTemplate &&
								currentMovingTemplate.length > 0 &&
								currentMovingTemplate.map((parameterTemplate, ix) => (
									<InputWrapper key={ix + 1} style={{ flex: 1 }}>
										{getParameterInput(
											parameterTemplate,
											ix,
											[
												behaviorSettings[entityId]?.findIndex((p) => p.property === "MOVING_BEHAVIOR"),
												"settings",
												ix + 1,
												/* Path is ix + 1, because index 0 is for NAME */
											],
											false
										)}
									</InputWrapper>
								))}
						</ParametersWrapper>
					</BehaviorSection>

					<BehaviorSection style={{ marginLeft: "0.5rem" }}>
						<InputWrapper>
							<InputLabel>Planning Action</InputLabel>
							{inputData.behaviors ? (
								<BehaviorSelect
									key={getBehaviorName(getPlanningAction(behaviorSettings[entityId])) || null}
									defaultValue={getBehaviorName(getPlanningAction(behaviorSettings[entityId])) || null}
									data={inputData.behaviors
										.filter((behavior) => behavior.type === "PLANNING_ACTION")
										.map((behavior) => ({ label: behavior.name, value: behavior.name }))}
									onChange={(value) => {
										setPlanningAction(value);
										handleBehaviorChange("PLANNING_ACTION", value, []);
									}}
									disabled={!isSuperuser || preview}
									clearable
								></BehaviorSelect>
							) : null}
						</InputWrapper>

						<ParametersWrapper>
							{currentPlanningTemplate &&
								currentPlanningTemplate.length > 0 &&
								currentPlanningTemplate.map((parameterTemplate, ix) => (
									<div key={ix + 1} style={{ flex: 1 }}>
										{getParameterInput(
											parameterTemplate,
											ix,
											[
												behaviorSettings[entityId]?.findIndex((p) => p.property === "PLANNING_ACTION"),
												"settings",
												ix + 1,
											],
											false
										)}
									</div>
								))}
						</ParametersWrapper>
					</BehaviorSection>
				</Flex>
			) : null}
		</BehaviorsContainer>
	);
};

export default BehaviorPanel;

const BehaviorSection = styled(Flex)`
	flex-direction: column;
	width: 50%;
	margin-top: 1rem;
`;

const BehaviorsContainer = styled(Flex)`
	display: flex;
	width: 100%;
	flex-direction: column;

	& > div {
		width: 100%;
	}
`;

const InputWrapper = styled(Flex)`
	flex-direction: column;
	color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.dark[9])};
	margin-bottom: 1rem;
`;

export const BehaviorSelect = styled(Select)`
	z-index: 1000;

	& .mantine-Select-input {
		z-index: 1000;
		color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.dark[9])};
		background-color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0])};
		border: 1px solid ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.light[5])};
	}

	& .mantine-Select-dropdown {
		z-index: 999;
		position: fixed !important; // Prevents dropdown from being cut off by overflow:auto
	}
`;

const StyledNumberInput = styled(NumberInput)`
	& .mantine-NumberInput-input {
		color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.dark[9])};
		background-color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0])};
		border: 1px solid ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.light[5])};
	}

	& .mantine-NumberInput-control {
		color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.dark[9])};
	}
`;

const ParametersWrapper = styled(Flex)`
	width: 100%;
	flex-direction: column;
`;

const InputLabel = styled(({ children, ...rest }: TextProps & { children: string | ReactNode }) => <Text {...rest}>{children}</Text>)`
	width: 100%;
	margin-bottom: 0.3rem;
`;
