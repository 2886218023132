import { Autocomplete, Button, createStyles, Flex, Group, Modal, NumberInput, Text, useMantineTheme } from "@mantine/core";
import * as Cesium from "cesium";
import { ImageryLayer, Viewer } from "resium";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { cesiumMapKey } from "../../behaviors-editor/BehaviorsEditor";
import { createAreas } from "../../behaviors-editor/functions/createAreas";
import { AreasData } from "../../../../../types/geometries";
import { toast } from "react-toastify";
import { createDescription } from "../../../../../common/createDescription";
import Popup from "../../../../../components/common/Popup";
import { resetAreaColor } from "../functions/resetAreaColor";

type EditionPhase = "start" | "creating" | "adding" | "editing";

interface IAreasEditor {
	value: number[];
	areasData: AreasData;
	addArea: (areaProperties: { id: number; type: string; label: string; owner: string; corridor: string }, geometry: any) => void;
	deleteArea: (areaId: number) => void;
	editArea: (areaId: number, areaProperties: { id: number; type: string; label: string; owner: string; corridor: string }) => void;
	onChange: (value: number[]) => void;
}

export const AreasEditor: React.FC<IAreasEditor> = ({ value, areasData, addArea, deleteArea, editArea, onChange }) => {
	const theme = useMantineTheme();
	const [isOpened, setIsOpened] = useState(false);
	const [viewer, setViewer] = useState<Cesium.Viewer | null>(null);
	const [dataSource, setDataSource] = useState<Cesium.GeoJsonDataSource | null>(null);
	const [currentPhase, setCurrentPhase] = useState<EditionPhase>("start");
	const { classes } = useStyles({ phase: currentPhase });
	const [currentControls, setCurrentControls] = useState<JSX.Element[]>();
	const [selectedEntity, setSelectedEntity] = useState<Cesium.Entity | null>(null);
	const [isDeleting, setIsDeleting] = useState(false);

	const handlerRef = useRef<Cesium.ScreenSpaceEventHandler | null>(null);
	const toBeRemovedZones = useRef<number[]>([]);
	const currentPhaseRef = useRef(currentPhase);
	const polygonVertices = useRef<Cesium.Cartesian3[]>([]);
	const polygonProperties = useRef<{ id: number; type: string; label: string; owner: string; corridor: string }>({
		id:
			getUniqueAreasProperties(areasData, "id")
				?.sort((a, b) => a.value - b.value)
				.pop()?.value + 1,
		type: "",
		label: "",
		owner: "",
		corridor: "",
	});

	const removePolygonDrawing = useCallback(() => {
		const exists =
			viewer?.entities.getById("new-area-polygon") ||
			viewer?.entities.getById("new-area-line") ||
			viewer?.entities.getById("new-area-point");

		if (exists) {
			viewer?.entities.remove(exists);
		}
	}, [viewer]);

	const drawPolygon = useCallback(
		(positions: Cesium.Cartesian3[]) => {
			removePolygonDrawing();

			if (positions.length > 2) {
				const polygon = viewer?.entities.add({
					id: "new-area-polygon",
					polygon: {
						hierarchy: positions,
						material: Cesium.Color.WHITE.withAlpha(0.4),
					},
				});

				return polygon;
			} else if (positions.length === 2) {
				const line = viewer?.entities.add({
					id: "new-area-line",
					polyline: {
						positions,
						width: 2,
						material: Cesium.Color.WHITE,
					},
				});

				return line;
			} else if (positions.length === 1) {
				const point = viewer?.entities.add({
					id: "new-area-point",
					position: positions[0],
					point: {
						pixelSize: 5,
						color: Cesium.Color.WHITE,
					},
				});

				return point;
			}
		},
		[viewer, removePolygonDrawing]
	);

	const phaseControls = useMemo(() => {
		return {
			start: (
				<Flex key="start" gap={"1rem"} w={"100%"}>
					{selectedEntity ? (
						<>
							<Popup
								title={"Confirm action"}
								modalButtonText={"Delete"}
								handleSubmit={() => {
									setIsDeleting(false);
									toBeRemovedZones.current.push(selectedEntity?.properties?.id._value);
									deleteArea(selectedEntity?.properties?.id._value);
									return true;
								}}
							>
								<Text color={theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3]}>
									{
										"Do you want to delete this element? (You must save scenario afterwards, otherwise zones property will be malformed)"
									}
								</Text>
							</Popup>
							<Button
								key="edit"
								onClick={() => {
									polygonProperties.current = {
										id: selectedEntity?.properties?.id._value,
										type: selectedEntity?.properties?.type._value,
										label: selectedEntity?.properties?.label._value,
										owner: selectedEntity?.properties?.owner._value,
										corridor: selectedEntity?.properties?.corridor._value,
									};
									setCurrentPhase("editing");
								}}
							>
								Edit
							</Button>
						</>
					) : null}
					<Button key="start" onClick={() => setCurrentPhase("creating")}>
						Add polygon
					</Button>
				</Flex>
			),

			creating: (
				<Flex key="creating" gap={"1rem"} w={"100%"}>
					<Button
						key="cancel"
						onClick={() => {
							if (viewer) viewer.selectedEntity = undefined;
							polygonVertices.current = [];
							removePolygonDrawing();
							setCurrentPhase("start");
						}}
					>
						Cancel
					</Button>
					<Button
						key="undo"
						onClick={() => {
							polygonVertices.current = polygonVertices.current.slice(0, -1);
							drawPolygon(polygonVertices.current);
						}}
					>
						Undo
					</Button>
					<Button
						key="finish"
						onClick={() =>
							polygonVertices.current.length > 2 ? setCurrentPhase("adding") : toast.warning("Polygon must have +2 vertices")
						}
					>
						Finish creating
					</Button>
				</Flex>
			),

			adding: (
				<Flex key="adding" gap={"1rem"} w={"100%"}>
					<Button
						key="cancel"
						onClick={() => {
							if (viewer) viewer.selectedEntity = undefined;
							polygonVertices.current = [];
							removePolygonDrawing();
							setCurrentPhase("start");
						}}
					>
						Cancel
					</Button>
					<Button
						key="save"
						onClick={() => {
							if (validateProperties(polygonProperties.current)) {
								addArea(polygonProperties.current, convertToGeoJson(polygonVertices.current));
								polygonVertices.current = [];
								polygonProperties.current = {
									id: polygonProperties.current.id + 1,
									type: "",
									label: "",
									owner: "",
									corridor: "",
								};
								removePolygonDrawing();
								setCurrentPhase("start");
							} else {
								toast.warning("Please fill all fields with valid values");
							}
						}}
					>
						Save
					</Button>
				</Flex>
			),

			editing: (
				<Flex key="editing" gap={"1rem"} w={"100%"}>
					<Button
						key="cancel"
						onClick={() => {
							setCurrentPhase("start");
							if (selectedEntity) {
								resetAreaColor(selectedEntity);
								setSelectedEntity(null);
								if (viewer) viewer.selectedEntity = undefined;
							}
						}}
					>
						Cancel
					</Button>
					<Button
						key="save"
						onClick={() => {
							if (validateProperties(polygonProperties.current)) {
								editArea(selectedEntity?.properties?.id._value, polygonProperties.current);
								setCurrentPhase("start");
							} else {
								toast.warning("Please fill all fields with valid values");
							}
						}}
					>
						Save
					</Button>
				</Flex>
			),
		};
	}, [drawPolygon, removePolygonDrawing, selectedEntity, isDeleting, viewer, theme, addArea, deleteArea, editArea]);

	function convertToGeoJson(coordinates: Cesium.Cartesian3[]) {
		const geometry = {
			type: "MultiPolygon",
			coordinates: [[[]]] as number[][][][],
		};

		coordinates.forEach((coordinate, index) => {
			let pos = Cesium.Cartographic.fromCartesian(coordinate);
			geometry.coordinates[0][0].push([Cesium.Math.toDegrees(pos.longitude), Cesium.Math.toDegrees(pos.latitude)]);
		});

		return geometry;
	}

	function getUniqueAreasProperties(areasData: AreasData, property: string) {
		return areasData.features
			?.map((feature: any) => {
				return {
					value: feature.properties[property],
					label: feature.properties[property],
				};
			})
			.filter((value, index, self) => self.findIndex((t) => t.value === value.value) === index);
	}

	function validateProperties(properties: { id: number; type: string; label: string; owner: string; corridor: string }) {
		const checkIfUnique = (value: string, property: string) => {
			return getUniqueAreasProperties(areasData, property)?.every((prop) => prop.value !== value);
		};
		return (
			Object.values(properties).every((value) => value !== "") &&
			properties.id > 0 &&
			checkIfUnique(properties.id.toString(), "id") &&
			checkIfUnique(properties.type, "label")
		);
	}

	useEffect(() => {
		currentPhaseRef.current = currentPhase;

		// Reset properties when changing phase
		if (currentPhase === "adding") {
			polygonProperties.current = {
				id:
					getUniqueAreasProperties(areasData, "id")
						?.sort((a, b) => a.value - b.value)
						.pop()?.value + 1,
				type: "",
				label: "",
				owner: "",
				corridor: "",
			};
		}
	}, [currentPhase, areasData]);

	useEffect(() => {
		if (viewer && areasData) {
			viewer.dataSources.remove(viewer.dataSources.getByName("areas")[0]);
			setDataSource(null);
		}
	}, [viewer, areasData]);

	useEffect(() => {
		if (viewer && areasData && !dataSource) {
			let newDataSource = new Cesium.GeoJsonDataSource("areas");
			newDataSource.load(areasData);
			const source = newDataSource as Cesium.GeoJsonDataSource;

			createAreas(viewer, source, () => {
				setDataSource(source);

				// Make areas opaque
				const entities = source.entities.values as (Cesium.Entity & { type: string })[];

				entities.forEach((entity) => {
					var color = null;

					if (entity.properties) {
						const type = entity.properties.type._value;
						if (type.indexOf("Blue") !== -1) {
							color = new Cesium.ColorMaterialProperty(Cesium.Color.BLUE.withAlpha(0.1));
						} else if (type.indexOf("Red") !== -1) {
							color = new Cesium.ColorMaterialProperty(Cesium.Color.RED.withAlpha(0.1));
						}

						entity.description = new Cesium.CallbackProperty(() => {
							return currentPhaseRef.current === "creating" ||
								currentPhaseRef.current === "adding" ||
								currentPhaseRef.current === "editing"
								? ""
								: createDescription(
										areasData.features.find((feature) => feature.properties.id === entity?.properties?.id._value)
											?.properties || {}
								  );
						}, false);
					}

					if (entity.polygon) {
						entity.polygon.material = color as Cesium.ColorMaterialProperty;
					}
				});

				const allPositions = entities
					.map((entity) => {
						return entity.polygon?.hierarchy?.getValue(new Cesium.JulianDate(1, 1)).positions;
					})
					.flat();

				const center = Cesium.BoundingSphere.fromPoints(allPositions).center;

				viewer.camera.setView({
					destination: new Cesium.Cartesian3(center.x, center.y, center.z),
					orientation: {
						heading: viewer.camera.heading,
						pitch: Cesium.Math.toRadians(-90),
						roll: viewer.camera.roll,
					},
				});

				viewer.camera.zoomOut(100000);
			});
		}
	}, [viewer, areasData, dataSource]);

	useEffect(() => {
		setCurrentControls([phaseControls[currentPhase]]);
	}, [currentPhase, phaseControls]);

	useEffect(() => {
		if (viewer && currentPhase === "creating") {
			const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
			handlerRef.current = handler;

			handler.setInputAction(function (click: any) {
				const clickPosition = click.position;
				const ray = viewer.camera.getPickRay(clickPosition);

				if (ray) {
					const position = viewer.scene.globe.pick(ray, viewer.scene);
					if (position) {
						polygonVertices.current.push(position);
						drawPolygon(polygonVertices.current);
					}
				}
			}, Cesium.ScreenSpaceEventType.LEFT_CLICK);
		}

		return () => {
			if (handlerRef.current) {
				handlerRef.current.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
				handlerRef.current.destroy();
				handlerRef.current = null;
			}
		};
	}, [viewer, currentPhase, drawPolygon]);

	useEffect(() => {
		const handleEntitySelect = (entity: Cesium.Entity | undefined) => {
			// Highlight selected entity
			if (entity && entity.polygon && entity.polygon.material) {
				entity.polygon.material = new Cesium.ColorMaterialProperty(Cesium.Color.WHITE.withAlpha(0.4));
			}

			if ((!entity || entity !== selectedEntity) && selectedEntity && selectedEntity.polygon && selectedEntity.polygon.material) {
				resetAreaColor(selectedEntity);
			}

			setSelectedEntity(entity || null);
		};

		if (viewer && currentPhase === "start") {
			viewer.selectedEntityChanged.addEventListener(handleEntitySelect);
		}

		return () => {
			viewer?.selectedEntityChanged.removeEventListener(handleEntitySelect);
		};
	}, [viewer, currentPhase, selectedEntity]);

	return (
		<>
			<Modal
				className={classes.modal}
				opened={isOpened}
				onClose={() => {
					removePolygonDrawing();
					setDataSource(null);
					viewer?.dataSources.remove(viewer?.dataSources.getByName("areas")[0]);
					onChange(value.filter((id) => !toBeRemovedZones.current.includes(id)));
					setIsOpened(false);
				}}
				title={currentPhase === "creating" ? "Draw polygon" : "Areas Editor"}
				size={"90vw"}
			>
				{currentPhase === "adding" || currentPhase === "editing" ? (
					<Flex
						style={{
							zIndex: 10,
							position: "absolute",
							top: "1rem",
							left: "1rem",
							justifyContent: "space-between",
							width: "calc(100% - 2rem)",
						}}
						gap={".5rem"}
					>
						<NumberInput
							placeholder="Set area id"
							required
							style={{ width: "100%" }}
							defaultValue={polygonProperties.current.id}
							disabled={currentPhase === "editing"}
							onChange={(value) => {
								console.log(value);
								if (typeof value === "number") {
									polygonProperties.current = {
										...polygonProperties.current,
										id: value,
									};
								}
							}}
						/>
						<Autocomplete
							data={getUniqueAreasProperties(areasData, "type") || []}
							placeholder="Set area type"
							required
							style={{ width: "100%" }}
							defaultValue={polygonProperties.current.type}
							onChange={(value) => {
								polygonProperties.current = {
									...polygonProperties.current,
									type: value,
								};
							}}
						/>
						<Autocomplete
							data={[]}
							placeholder="Set area label"
							required
							style={{ width: "100%" }}
							defaultValue={polygonProperties.current.label}
							onChange={(value) => {
								polygonProperties.current = {
									...polygonProperties.current,
									label: value,
								};
							}}
						/>
						<Autocomplete
							data={getUniqueAreasProperties(areasData, "owner") || []}
							placeholder="Set area owner"
							required
							style={{ width: "100%" }}
							defaultValue={polygonProperties.current.owner}
							onChange={(value) => {
								polygonProperties.current = {
									...polygonProperties.current,
									owner: value,
								};
							}}
						/>
						<Autocomplete
							data={getUniqueAreasProperties(areasData, "corridor") || []}
							placeholder="Set area corridor"
							required
							style={{ width: "100%" }}
							defaultValue={polygonProperties.current.corridor}
							onChange={(value) => {
								polygonProperties.current = {
									...polygonProperties.current,
									corridor: value,
								};
							}}
						/>
					</Flex>
				) : null}
				<Flex h={"80vh"}>
					<Viewer
						full
						ref={(e) => {
							if (e && e.cesiumElement) {
								setViewer(e.cesiumElement);
							}
						}}
						animation={false}
						timeline={false}
						baseLayerPicker={false}
						navigationHelpButton={false}
						sceneModePicker={false}
						homeButton={false}
						geocoder={false}
						fullscreenButton={false}
						selectionIndicator={false}
					>
						<ImageryLayer
							imageryProvider={
								new Cesium.UrlTemplateImageryProvider({
									url: `https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=${cesiumMapKey}`,
									minimumLevel: 0,
									maximumLevel: 20,
									credit: new Cesium.Credit(
										'\u003ca href="https://www.maptiler.com/copyright/" target="_blank"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href="https://www.openstreetmap.org/copyright" target="_blank"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e',
										true
									),
								})
							}
						/>
					</Viewer>

					<Flex pt={"1rem"} style={{ position: "absolute", bottom: "1rem", right: "1rem" }}>
						{currentControls}
					</Flex>
				</Flex>
			</Modal>

			<Group position="center">
				<Button w={"100%"} onClick={() => setIsOpened(true)} component="div" variant={"filled"}>
					{/* component="div" is needed to prevent html from throwing wrong nesting error if used inside Accordion.Control */}
					Create zones
				</Button>
			</Group>
		</>
	);
};

const useStyles = createStyles((theme, { phase }: { phase: string }, _variations) => ({
	modal: {
		".mantine-Modal-header": {
			height: "3rem",
		},

		".mantine-Modal-body": {
			position: "relative",
		},

		".mantine-InputWrapper-label": {
			color: "white",
		},

		".cesium-infoBox ": {
			marginTop: "0 !important",
			top: phase !== "start" ? "-5rem !important" : ".5rem !important",
		},
	},
}));
