export const createDescription = (properties: Record<string, any>) => {
	let html = "";

	for (const key in properties) {
		if (properties.hasOwnProperty(key)) {
			const value = properties[key];
			if (value !== undefined) {
				html += `<tr><th>${key}</th><td>${value}</td></tr>`;
			}
		}
	}

	if (html.length > 0) {
		html = `<style>
		body {
			background: rgba(38, 38, 38, 1)
		}
		.cesium-infoBox-defaultTable tr:nth-child(even) {
			background-color: rgba(38, 38, 38, 1) !important;
		}
		.cesium-infoBox-defaultTable tr:nth-child(odd) {
			background-color: rgba(60, 60, 60, 1) !important;
		}
        .cesium-infoBox-description, cesium-infoBox-description * {
            padding: 0;
            margin: 0;
        }
        .cesium-infoBox-defaultTable{
            border-spacing: 0 !important;
        }
		</style>
		<table class="cesium-infoBox-defaultTable"><tbody>${html}</tbody></table>`;
	}

	return html;
};
