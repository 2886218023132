import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import { useNavigate } from "react-router-dom";
import { Button, createStyles } from "@mantine/core";
import makeRequest from "../../common/makeRequest";
import Folders from "./TreeComponents";
import { AutocompleteWithoutSuggestions } from "../../components/elements-list/ElementsList";

const ResourcesMenu: React.FC = () => {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const [searchValue, setSearchValue] = useState<string | null>(null);
	const [projects, setProjects] = useState<Record<string, any>>({});

	const fetchDocumentsList = async () => {
		const response = await makeRequest(`documents`, "GET");
		if (!response.ok) throw new Error("Failed to fetch projects");
		return response.json();
	};

	const getAllFilesNames = useCallback((content: Record<string, any>): string[] => {
		let files: string[] = [];
		for (const key in content) {
			if (content[key] === null) files.push(key);
			else files = files.concat(getAllFilesNames(content[key]));
		}
		return files;
	}, []);

	useEffect(() => {
		fetchDocumentsList().then((pdfs) => {
			setProjects(pdfs.projects);
		});
	}, []);

	return (
		<MenuContainer>
			<AutocompleteWithoutSuggestions
				w={"100%"}
				placeholder="Search"
				data={getAllFilesNames(projects).map((fileName) => fileName)}
				onChange={(value) => {
					setSearchValue(value);
				}}
				mb={"1rem"}
				limit={0}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						e.preventDefault();
						e.currentTarget.blur();
					}
				}}
			></AutocompleteWithoutSuggestions>

			<Folders content={projects} depth={0} path={""} filterByValue={searchValue || undefined} />

			<Button className={classes.menuBtn} variant="outline" onClick={() => navigate("symbols")}>
				{"Military symbols"}
			</Button>
		</MenuContainer>
	);
};

const useStyles = createStyles((theme) => ({
	menuBtn: {
		width: "100%",
		height: "auto",
		display: "flex",
		padding: "1rem",

		"& .mantine-Button-label": {
			whiteSpace: "break-spaces",
			// textAlign: "left",
		},

		"& .mantine-Button-inner": {
			justifyContent: "flex-start",
		},
	},
}));

const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
	flex-wrap: wrap;
	padding: 0 10rem;
	justify-content: center;
    gap: 1rem;

	& button, a {
		// width: 100%;
	}

	@media (max-width: 768px) {
		padding: 0;
		& button, a {
			width: 12rem;
			height: 12rem;
		}
	}
}`;

export default ResourcesMenu;
