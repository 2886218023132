import React from "react";
import makeRequest from "../../common/makeRequest";
import { Accordion, createStyles, useMantineTheme } from "@mantine/core";
import { AccordionItem, AccordionPanel } from "../ForcePackages/force-package-page/force-package-accordions/NestedAccordions";

interface FolderProps {
	content: Record<string, any>;
	depth: number;
	path: string;
	filterByValue?: string;
}

const Folders: React.FC<FolderProps> = ({ content, depth, path, filterByValue }) => {
	const theme = useMantineTheme();
	const { classes } = useStyles();

	const deepSearchForFileName = (content: Record<string, any>, filterByValue: string, isTopLevel?: boolean): boolean => {
		for (const key in content) {
			if (key.toLowerCase().includes(filterByValue.toLowerCase())) return true;
			if (content[key] !== null && deepSearchForFileName(content[key], filterByValue)) return true;
		}
		return false;
	};

	return (
		<Accordion className={classes.accordionContainer} multiple>
			{Object.entries(content)
				.filter((entry) => entry[0].length > 0)
				.map(([key, value]) => {
					if (value === null)
						return <File key={key} fileKey={path.length === 0 ? key : path + "/" + key} filterByValue={filterByValue} />;
					else {
						return (
							<AccordionItem
								key={key}
								value={key}
								style={{
									borderRadius: theme.radius.sm,
									display: filterByValue
										? deepSearchForFileName(content[key], filterByValue)
											? "block"
											: "none"
										: "block",
								}}
							>
								<Accordion.Control
									className={path.length === 0 ? classes.topLevelAccordionControl : ""}
									style={{ borderRadius: theme.radius.sm }}
								>
									{key}
								</Accordion.Control>
								<AccordionPanel>
									<Folders
										key={key}
										content={value}
										depth={depth + 1}
										path={path.length === 0 ? key : path + "/" + key}
										filterByValue={filterByValue}
									/>
								</AccordionPanel>
							</AccordionItem>
						);
					}
				})}
		</Accordion>
	);
};

export default Folders;

interface FileProps {
	fileKey: string;
	filterByValue?: string;
}

const File: React.FC<FileProps> = ({ fileKey, filterByValue }) => {
	const fetchDocumentUrl = async (key: string) => {
		const response = await makeRequest(`documents/url?key=${encodeURIComponent(key)}`, "GET");
		if (!response.ok) throw new Error("Failed to fetch projects");
		return response.json();
	};

	const handleFileClick = async () => {
		try {
			const { url } = await fetchDocumentUrl(fileKey);
			window.open(url, "_blank");
		} catch (error) {
			console.error("Error opening file:", error);
		}
	};

	return (
		<div
			style={{
				marginLeft: "20px",
				cursor: "pointer",
				display: filterByValue ? (fileKey.toLowerCase().includes(filterByValue.toLowerCase()) ? "block" : "none") : "block",
			}}
			onClick={handleFileClick}
		>
			📄 {fileKey}
		</div>
	);
};

const useStyles = createStyles((theme) => ({
	folderContainer: {
		color: theme.colorScheme === "dark" ? theme.colors.light[5] : theme.black,
		border: `1px solid ${theme.colors.accentRed[5]}`,
		borderRadius: theme.radius.sm,
	},

	folderHeader: {
		display: "flex",
		alignItems: "center",
		height: "3rem",
		padding: "0 1rem",
	},

	innerFolderContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "1rem",
		padding: "1rem",
	},

	accordionContainer: {
		width: "100%",
		borderBottom: "none",

		"& > :not(:first-of-type)": {
			marginTop: "1rem",
		},

		"& .mantine-Accordion-control": {
			color: theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.dark[3],
		},

		"& .mantine-Accordion-content": {
			padding: "1rem !important",
		},
	},

	topLevelAccordionControl: {
		border: `1px solid ${theme.colors.accentRed[5]}`,

		":hover": {
			backgroundColor: `${theme.colors.appMainColor[6]} !important`,
			cursor: "pointer !important",
		},
	},

	commanderText: {
		textAlign: "center",
		marginTop: "1rem",
		color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.dark[3],

		"& > hr": {
			margin: "0.5rem 0",
			backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.light[5],
			border: "none",
			padding: "1px",
		},
	},
}));
