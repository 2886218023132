import { forwardRef } from "react";
import { Group, Avatar, Text } from "@mantine/core";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
	image: string;
	label: string;
	description: string;
}

export const CustomSelectItem = forwardRef<HTMLDivElement, ItemProps>(({ image, label, description, ...others }: ItemProps, ref) => {
	return (
		<div ref={ref} {...others}>
			<Group noWrap>
				<Avatar src={image} />
				<div>
					<Text size="sm">{label}</Text>
					<Text size="xs" opacity={0.65}>
						{description}
					</Text>
				</div>
			</Group>
		</div>
	);
});
